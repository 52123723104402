import React from "react";
import {Heading, Text, Flex} from "@chakra-ui/react";
import {colorForScore} from "../helpers/helpers";

function Legend() {
    return (
        <Flex m={3}>
            <Heading mx={3} as="h3" size="md" fontFamily="Regular3270">Rarity Legend</Heading>
            <Text mx={3} color={colorForScore(6)}>Mythic</Text>
            <Text mx={3} color={colorForScore(5)}>Legendary</Text>
            <Text mx={3} color={colorForScore(4)}>Epic</Text>
            <Text mx={3} color={colorForScore(3)}>Rare</Text>
            <Text mx={3} color={colorForScore(2)}>Uncommon</Text>
            <Text mx={3} color={colorForScore(1)}>Common</Text>
        </Flex>
    )
}

export default Legend;