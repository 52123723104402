import React, {useEffect, useState} from 'react';
import {useMoralis} from "react-moralis";
import {Link as LinkRouter} from 'react-router-dom';
import AuthHeader from './AuthHeader';
import SwitchColorMode from './SwitchColorMode';
import {
    Flex,
    Link,
    IconButton,
    Spacer,
    Center,
    Tooltip,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import {Menu, MenuButton, MenuList, MenuItem, useColorMode} from '@chakra-ui/react';
import {FaTwitter, FaDiscord, FaSearch} from 'react-icons/fa';
import {HamburgerIcon, CloseIcon} from '@chakra-ui/icons';
import useERC20 from "../hooks/useERC20";

function HeaderMenu() {

    const {colorMode} = useColorMode();
    const isDark = colorMode === "dark";

    const midDevices = useBreakpointValue({base: 'none', md: 'flex'});
    const smallDevices = useBreakpointValue({base: 'flex', md: 'none'});

    const {web3, isAuthenticated, user} = useMoralis();
    const [address, setAddress] = useState(null);
    const [tokens, setValue, isLoading] = useERC20("eth", address);

    useEffect(() => {
        if (isAuthenticated) {
            setAddress(user.get("ethAddress"));
        }
    }, [isAuthenticated, user]);

    useEffect(() => {
        if (!isAuthenticated) {
            setValue([]);
            setAddress(null);
        }
    }, [isAuthenticated, setValue, setAddress])

    return (

        <Flex w="100%" p={4} position={"fixed"} top={0} backgroundColor={isDark ? "brand.dark" : "brand.gray"}>
            <Center>
                <Link
                    as={LinkRouter}
                    to="/"
                    _hover={{textDecor: 'none'}}
                    _focus={{textDecor: 'none'}}
                >
                    <Flex flexDir={["column", "column", "column", "row", "row"]} alignItems="center">
                        <Center mr="3" fontSize="2xl" fontFamily="Regular3270">GΞAR</Center>
                        <Center fontSize="sm" fontFamily="Regular3270">for Punks</Center>
                    </Flex>

                </Link>
            </Center>

            <Spacer/>

            <Center mx={3} backgroundColor={isDark ? "brand.dark" : "brand.gray"}>
                {tokens &&
                    <>
                        {/** TODO - Add the derivatives here also? **/}
                        {tokens.map((item, index) => (
                            <Flex key={index} alignItems="flex-start" p={2}>
                                <Text>{item.name}</Text>
                                <Text>{web3.utils.fromWei(item.balance, 'ether')} $NEURO</Text>
                            </Flex>
                        ))}
                    </>
                }
            </Center>

            <Center>
                <AuthHeader/>
            </Center>

            <Center display={midDevices} alignSelf="center">
                <Tooltip hasArrow label="Search for GΞAR">
                    <IconButton
                        _hover={{textDecor: 'none'}}
                        _focus={{textDecor: 'none'}}
                        as={LinkRouter} to="/search" aria-label="Search" ml={2} icon={<FaSearch/>} rounded="xl"/>
                </Tooltip>

                <Tooltip hasArrow label="Twitter">
                    <IconButton as="a" aria-label="Twitter" ml={2} icon={<FaTwitter/>} rounded="xl"
                                onClick={() => window.open("https://twitter.com/gearforpunks")}/>
                </Tooltip>
                <Tooltip hasArrow label="Discord">
                    <IconButton as="a" aria-label="Discord" ml={2} icon={<FaDiscord/>} rounded="xl"
                                onClick={() => window.open("https://discord.gg/SzGmVjjMbG")}/>
                </Tooltip>
            </Center>

            <Center display={smallDevices} alignSelf="center">
                <Menu>
                    {({isOpen}) => (
                        <>
                            <MenuButton
                                as={IconButton}
                                aria-label="Menu"
                                icon={isOpen ? <CloseIcon/> : <HamburgerIcon/>}
                                rounded="xl"
                                ml={2}
                            />
                            <MenuList p={4}>
                                <MenuItem as={LinkRouter} to="/search" icon={<FaSearch/>}>
                                    Search
                                </MenuItem>
                                <MenuItem
                                    onClick={() => window.open("https://twitter.com/gearforpunks")}
                                    icon={<FaTwitter/>}>
                                    Twitter
                                </MenuItem>
                                <MenuItem
                                    onClick={() => window.open("https://discord.gg/SzGmVjjMbG")}
                                    icon={<FaDiscord/>}>
                                    Discord
                                </MenuItem>
                            </MenuList>
                        </>
                    )}
                </Menu>
            </Center>

            <Center alignSelf="center">
                <SwitchColorMode/>
            </Center>

        </Flex>
    );
}

export default HeaderMenu;
