import HeaderComponent from "./components/HeaderComponent";
import {Route, Switch} from "react-router-dom";
import Overview from "./pages/Overview";
import Search from "./pages/Search";
import About from "./pages/About";
import React from "react";

function App() {
  return (
    <>
        <HeaderComponent />

        <Switch>
            <Route path='/' exact>
                <Overview />
            </Route>
            <Route path='/search'>
                <Search />
            </Route>
            <Route path='/about'>
                <About />
            </Route>
        </Switch>

        {/*TODO - add 404 page*/}
    </>
  );
}

export default App;
