import React, {useEffect, useState} from 'react';
import {Flex, Divider, Heading, Text, Link, Image, Box} from "@chakra-ui/react";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {colorForScore, scoreForOccurrence} from "../helpers/helpers";
import rarities from "../data/rare.json";
import gearItems from "../data/gear.json";
import occurrences from "../data/occurrences.json";

function Gear({gear}) {

    const GEAR_COLLECTION_ENDPOINT = "https://opensea.io/assets/0xff796cbbe32b2150a4585a3791cadb213d0f35a3/";

    const [scores, setScores] = useState(null);
    const [items, setItems] = useState([]);
    const [image, setImage] = useState(null);

    useEffect(() => {
        // TODO - refactor
        const scores = rarities.find((loot) => loot.lootId === Number(gear.token_id));
        setScores(scores);

        const items = gearItems.find((loot, index) => index + 1 === Number(gear.token_id));
        setItems(items[Object.keys(items)[0]]);

    }, [gear]);

    useEffect(() => {
        fetch(gear.token_uri)
            .then(response => response.json())
            .then(data => setImage(data.image))
    }, [gear])


    function getOccurrence(itemName) {
        return occurrences[itemName];
    }

    function getItemRarityColor(itemOccurrence) {
        return colorForScore(scoreForOccurrence(itemOccurrence));
    }

    return (
        <Flex m={5} p={1} border="1px solid" minW="50vh" flexDir="column">

            <Heading m={5} as="h4" size="md" fontFamily="Regular3270">
                <Link href={`${GEAR_COLLECTION_ENDPOINT}/${gear.token_id}`} isExternal>
                    STASH #{gear.token_id} <ExternalLinkIcon mx="2px"/>
                </Link>
            </Heading>

            <Flex m={2} p={2} flexDir="column" minW="50vh" alignItems="Left">
                {items &&
                <>
                    <Text color={getItemRarityColor(getOccurrence(items.chest))}>Chest: {items.chest}</Text>
                    <Text color={getItemRarityColor(getOccurrence(items.eyes))}>Eyes: {items.eyes}</Text>
                    <Text color={getItemRarityColor(getOccurrence(items.foot))}>Foot: {items.foot}</Text>
                    <Text color={getItemRarityColor(getOccurrence(items.hand))}>Hand: {items.hand}</Text>
                    <Text color={getItemRarityColor(getOccurrence(items.head))}>Head: {items.head}</Text>
                    <Text color={getItemRarityColor(getOccurrence(items.implant))}>Implant: {items.implant}</Text>
                    <Text color={getItemRarityColor(getOccurrence(items.waist))}>Waist: {items.waist}</Text>
                    <Text color={getItemRarityColor(getOccurrence(items.weapon))}>Weapon: {items.weapon}</Text>
                </>
                }
                {image &&
                <>
                    <Box mt={5} boxSize="sm">
                        <Image src={image} alt="NFT Image"/>
                    </Box>
                </>
                }
            </Flex>

            <Divider m={2} p={2} w="90%" alignSelf="center"/>

            {scores &&
            <>
                <Flex m={2} p={2} flexDir="column" w="50vh">
                    <p>Score: {scores.score}</p>
                    <p>Rarest: {scores.rarest}</p>
                    {/*<p>LootId: {scores.lootId}</p>*/}
                    {/*<Box>Name: {gear.name}</Box>*/}
                    {/*<Box>Symbol: {gear.symbol}</Box>*/}
                    {/*<Box>Token Id: {gear.token_id}</Box>*/}
                    {/*<Box>Amount: {gear.amount}</Box>*/}
                </Flex>
            </>
            }
        </Flex>
    )
}

export default Gear;