import React from 'react';
import {FaSun, FaMoon} from 'react-icons/fa';
import {Center, Tooltip, IconButton, useColorMode} from '@chakra-ui/react';

function SwitchColorMode() {

    const {colorMode, toggleColorMode} = useColorMode();
    const isDark = colorMode === "dark";

    return (
        <Center>
            <Tooltip hasArrow label={isDark ? "Switch to light mode" : "Switch to dark mode"}>
                <IconButton as="a" aria-label="Toggle Color Mode" ml={2}
                            icon={colorMode === 'light' ? <FaSun/> : <FaMoon/>}
                            rounded="xl" onClick={toggleColorMode}/>
            </Tooltip>
        </Center>
    );
}

export default SwitchColorMode;