import React from 'react';
import {Tooltip, Button, Text, useToast} from '@chakra-ui/react';
import {FaWallet, FaEthereum} from 'react-icons/fa';
import {useMoralis} from 'react-moralis';

function AuthHeader() {

    const {
        authenticate,
        isAuthenticated,
        isAuthenticating,
        isLoggingOut,
        logout,
        user,
    } = useMoralis();

    // useEffect(() => {
    //     if (!isWeb3Enabled) {
    //         enableWeb3();
    //     }
    // }, [web3.currentProvider]);

    const toastIdForAuthError = "authError"
    const toastIdForError = "otherError"
    const toast = useToast();

    function showErrorToast(id, title, description) {
        if (!toast.isActive(id)) {
            toast({
                id: id,
                title: title,
                description: description,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            })
        }
    }

    return (
        <>
            {!isAuthenticated &&
                <Tooltip hasArrow label="Connect Wallet">
                    <Button isLoading={isAuthenticating} loadingText="Connecting..."
                        leftIcon={<FaWallet/>} aria-label="Connect Wallet" rounded="xl"
                        onClick={
                            () => {
                                authenticate({
                                    onComplete: () => {
                                        // TODO - redirect to Overview page?
                                    },
                                    onError: (error) => showErrorToast(toastIdForAuthError, "Error Authenticating", error.message),
                                    onSuccess: () => {
                                    }
                                }).then()
                            }
                        }>
                        Connect Wallet
                    </Button>
                </Tooltip>
            }

            {isAuthenticating &&
                <Tooltip>
                    <Button
                        aria-label="Address" rounded="xl" ml={2}
                        onClick={() => logout(
                            {onError: (error) => showErrorToast(toastIdForError, "Error", error.message)}
                        )}
                    >
                        Cancel
                    </Button>
                </Tooltip>
            }

            {isAuthenticated &&
                <>
                <Tooltip hasArrow label={user.get("ethAddress")}>
                    <Button maxWidth="220px"
                            leftIcon={<FaEthereum/>}
                            aria-label="Address" rounded="xl"
                    >
                        <Text isTruncated> {user.get("ethAddress")}</Text>
                    </Button>
                </Tooltip>
                <Tooltip hasArrow label="Disconnect Wallet">
                    <Button isLoading={isLoggingOut}
                            leftIcon={<FaWallet/>}
                            aria-label="Disconnect Wallet" rounded="xl" ml={2}
                            onClick={() => logout(
                                {onError: (error) => showErrorToast(toastIdForError, "Error", error.message)}
                            )}
                    >
                        Disconnect Wallet
                    </Button>
                </Tooltip>
            </>
            }
        </>
    );
}

export default AuthHeader;