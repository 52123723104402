import React from "react";
import {Heading, Flex, useColorMode} from "@chakra-ui/react";

function Info({title}) {

    const {colorMode} = useColorMode();
    const isDark = colorMode === "dark";

    return (
        <Flex flexDir="column" mt="150px" p={5} rounded="xl"
              maxW="2000px"
              minH="600px"
              alignItems="column"
              backgroundColor={isDark ? "brand.dark" : "brand.gray"}
        >
            <Flex flexDir="column" p={5}>
                <Flex my={5}>
                    <Heading as="h3" size="md" mt="3" fontFamily="Regular3270">
                        {title}
                    </Heading>
                </Flex>

                <Flex mb={5}>
                    <Heading as="h3" size="md" fontFamily="Regular3270">
                        Start by connecting with Metamask.
                    </Heading>
                </Flex>

                <Flex mt={5}>
                    <Heading as="h3" size="md" fontFamily="Regular3270">
                        You can also search for GΞAR owned by others.
                    </Heading>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Info;