import React, {useRef, useState} from 'react';
import {
    Heading,
    Flex,
    Input,
    Text,
    useColorMode,
    Button,
    FormControl,
    Divider,
    HStack,
    InputGroup,
} from '@chakra-ui/react';

import BeatLoader from "react-spinners/BeatLoader";
import {useMoralis} from "react-moralis";
import Gear from "../components/Gear";
import Legend from "../components/Legend"

function Search() {

    const {colorMode} = useColorMode();
    const isDark = colorMode === "dark";

    const {Moralis} = useMoralis();
    const addressInput = useRef();
    const [NFTs, setNFTs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const GEAR_TOKEN_ADDRESS = "0xFf796cbbe32B2150A4585a3791CADb213D0F35A3";

    async function searchGEAR(event) {
        event.preventDefault();
        let address = addressInput.current.value;
        let chain = "eth";
        const params = {chain: chain, address: address, token_address: GEAR_TOKEN_ADDRESS}

        try {
            setIsLoading(true);
            const response = await Moralis.Web3API.account.getNFTsForContract(params)
            setIsLoading(false);
            setNFTs(response.result);
        } catch (e) {
            setNFTs([]);
        } finally {
            setIsLoading(false);
            console.log("NFTS", NFTs);
        }
    }

    return (
        <Flex flexDir="column" mt="150px" p={5} rounded="xl"
              maxW="2000px"
              minH="600px"
              alignItems="flex-start"
              backgroundColor={isDark ? "brand.dark" : "brand.gray"}
        >

            <Heading as="h3" size="md" p={5} fontFamily="Regular3270">
                Work in progress (search doesn't support ENS for now. eg test.eth)
            </Heading>

            <form onSubmit={searchGEAR}>
                <HStack spacing="2" ml={5}>
                    <Text minW="150px">Search for GΞAR</Text>
                    <FormControl isRequired>
                        <InputGroup>
                            <Input type="text" placeholder="Eth address" aria-label="Eth address" ref={addressInput}/>
                        </InputGroup>
                    </FormControl>
                    <Button
                        type="submit"
                        isLoading={isLoading}
                        spinner={<BeatLoader size={8} color="white"/>}
                    >
                        Search
                    </Button>
                </HStack>
            </form>

            <Divider m={2} p={2} w="90%" alignSelf="center"/>

            <Flex p={5} mt={20} alignItems="center" flexDir={["column"]}>

                {NFTs.length > 0 &&
                    <>
                        <Legend />

                        {NFTs.map((item, index) => (
                            <Flex key={index} alignItems="flex-start">
                                <Gear gear={item}/>
                            </Flex>
                        ))}
                    </>
                }

                {NFTs.length === 0 &&
                    <>
                        {/*TODO - create a component to handle no results*/}
                        <Flex flexDir="column" mt={10}>
                            Nothing found
                        </Flex>
                    </>
                }
            </Flex>
        </Flex>
    )
}

export default Search;