import React from "react";
import GearCollection from "../components/GearCollection";
import {useMoralis} from "react-moralis";
import Info from "../components/Info";

function Overview() {

    const {isAuthenticated} = useMoralis();

    return (
        <>
            {!isAuthenticated && <Info title={"Check out your GΞAR for Punks."}/>}
            {isAuthenticated && <GearCollection title="" />}
        </>
    )
}

export default Overview;