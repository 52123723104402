import {useEffect, useState} from "react";
import {useMoralis} from "react-moralis";

function useERC20(chain, address) {

    const [value, setValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {isAuthenticated, Moralis} = useMoralis();
    const NEURO_TOKEN_ADDRESS = "0xe71946b31bda22526b2de702bca8b457ef85c4f1";

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true);
            // TODO - change with specific token call
            Moralis.Web3API.account.getTokenBalances({chain: chain, address: address})
                .then(data => {
                    let tokens = data.filter(data => data.token_address === NEURO_TOKEN_ADDRESS)
                    setValue(tokens);
                    setIsLoading(false);
                })
        }
    }, [isAuthenticated, Moralis, chain, address])

    return [value, setValue, isLoading];
}

export default useERC20;