import {useEffect, useState} from "react";
import {useMoralis} from "react-moralis";

function useNFTs(chain, address) {

    const [value, setValue] = useState([]);
    const [total, setTotal] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {isAuthenticated, Moralis} = useMoralis();

    useEffect( () => {
        if (isAuthenticated) {
            setIsLoading(true);
            // TODO - change with Moralis.Web3API.account.getNFTsForContract
            Moralis.Web3API.account.getNFTs({chain: chain, address: address})
                .then(data => {
                    let gear = data.result.filter(data => data.symbol === "GEAR")
                    setValue(gear);
                    setTotal(gear.length);
                    setIsLoading(false);
                });
        }
    }, [isAuthenticated, Moralis, chain, address])

    return [value, total, isLoading];
}

export default useNFTs;