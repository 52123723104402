import {extendTheme} from '@chakra-ui/react';

const overrides = {
    config: {
        initialColorMode: 'dark',
    },
    styles: {
        global: {
            body: {
                fontFamily: "Regular3270",
                fontSize: "md"
            },
        }
    },
    colors: {
        brand: {
            light: "#bdb508",
            dark: "#171923",
            gray: "#F5F5F5",
        }
    }
};

const gearTheme = extendTheme(overrides);

export default gearTheme;