export const colors = {
    common: "#838383",
    uncommon: "#00DC82",
    rare: "#2e82ff",
    epic: "#c13cff",
    legendary: "#f8b73e",
    mythic: "#ff44b7",
};

export const styleLegend = {
    common: {
        color: colors.common,
    },
    uncommon: {
        color: colors.uncommon,
    },
    rare: {
        color: colors.rare,
    },
    epic: {
        color: colors.epic,
    },
    legendary: {
        color: colors.legendary,
    },
    mythic: {
        color: colors.mythic,
    },
}

export function colorForScore(score) {
    switch (score) {
        case 6:
            return colors.mythic;
        case 5:
            return colors.legendary;
        case 4:
            return colors.epic;
        case 3:
            return colors.rare;
        case 2:
            return colors.uncommon;
        case 1:
        default:
            return colors.common;
    }
}

export function scoreForOccurrence(occurrence) {
    if (occurrence === 1) {
        return 6;
    } else if (occurrence <= 9) {
        return 5;
    } else if (occurrence <= 100) {
        return 4;
    } else if (occurrence <= 357) {
        return 3;
    } else if (occurrence <= 374) {
        return 2;
    } else {
        return 1;
    }
}