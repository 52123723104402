import React from 'react';
import {Flex, Text, Spinner, Heading, Link, useColorMode} from "@chakra-ui/react";
import {ExternalLinkIcon} from '@chakra-ui/icons'
import useNFTs from "../hooks/useNFTs";
import Gear from "./Gear";
import Legend from "../components/Legend"
import {useMoralis} from "react-moralis";

function GearCollection() {

    const {colorMode} = useColorMode();
    const isDark = colorMode === "dark";

    const {user} = useMoralis();
    const [NFTs, totalNFTs, isLoading] = useNFTs("eth", user.get("ethAddress"));

    return (
        <Flex flexDir="column" mt="150px" p={5} rounded="xl"
              maxW="2000px"
              minH="600px"
              alignItems="column"
              backgroundColor={isDark ? "brand.dark" : "brand.gray"}>

            {isLoading &&
                <Flex alignItems="center" flexDir="column" p={5}>
                    <Spinner thickness="4px"
                             speed="0.65s"
                             emptyColor="gray.200"
                             color="blue.500"
                             size="xl"
                             label="loading..."/>
                </Flex>
            }

            <Flex p={5} mt={20} alignItems="center" flexDir={["column"]}>

                {NFTs.length > 0 &&
                    <>
                        <Legend />

                        {NFTs.map((item, index) => (
                            <Flex key={index} alignItems="flex-start">
                                <Gear gear={item}/>
                            </Flex>
                        ))}
                    </>
                }

                {totalNFTs === 0 &&
                    <>
                        {/*TODO - create a component to handle no results*/}
                        <Flex flexDir="column" mt={10}>
                            <Heading p={5} as="h5" size="sm" fontFamily="Regular3270">
                            No GΞAR on this address.
                        </Heading>
                            <Heading p={5} as="h5" size="sm" fontFamily="Regular3270">
                            Search for other's people GΞAR by using the search or connect to a
                            different wallet.
                        </Heading>
                            <Heading p={5} as="h5" size="sm" fontFamily="Regular3270">
                            <Link href="https://opensea.io/collection/gear-for-punks" isExternal>
                                You can also check out the OpenSea collection <ExternalLinkIcon mx="2px"/>
                            </Link>
                        </Heading>
                        </Flex>
                    </>
                }
            </Flex>
        </Flex>
    )
}

export default GearCollection;