import React from "react";

function About() {
    return (
        <>
            <p>TODO - About page</p>
        </>
    )
}

export default About;